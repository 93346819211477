import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { firebase, firebaseInit } from './firebase'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'
import './styles/app.scss'
import i18n from './i18n'
import VueAspectRatio from "vue-aspect-ratio"
import VueNumber from 'vue-number-animation'
import Ads from 'vue-google-adsense'

Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

Vue.component("vue-aspect-ratio", VueAspectRatio)

Vue.use(VueNumber)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

console.groupCollapsed("env")
console.log(process.env)
console.groupEnd()

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.request.use(async config => {
  if (config.baseURL === process.env.VUE_APP_API_URL) {
    const token = await store.dispatch("authModule/login")
    // console.log(config.url, !!token)
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
},
  error => Promise.reject(error)
);

Vue.prototype.prefix = process.env.NODE_ENV === "development" ? "LOC" : process.env.VUE_APP_STAGING === "true" ? "DEV" : ""

Vue.prototype.headless = navigator.webdriver

Vue.config.productionTip = false

firebaseInit()

firebase.auth().onAuthStateChanged(async user => {
  await store.dispatch("authModule/login", { u: user, authLoaded: true })
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')