<template>
  <div class="d-inline-block pointer">
    <b-tag :id="_uid.toString()" no-remove variant="light" class="m-0">
      #{{ value }}
    </b-tag>
    <b-popover :target="_uid.toString()" triggers="hover" placement="top">
      <b-link
        class="card-link"
        :to="{ name: 'Games', query: { cimkek: JSON.stringify([value]) } }"
      >
        további #{{ value }} játékok
      </b-link>
    </b-popover>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    }
  }
};
</script>
